// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import {on} from 'delegated-events'
import {observe} from 'selector-observer'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@github/text-expander-element"
import "@github/include-fragment-element"
import "./suggestions"
import "./action-cable-element"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

observe('img', el => {
  el.addEventListener('error', function() {
    el.src = document.head.querySelector('[name="fallback-image-url"]').content
  })
})

on('click', '.js-review-later', _ => {
  document.querySelector('.js-review-fields').remove()
})

on('change', '.js-product-select', event => {
  const showNewFields = !event.currentTarget.value
  document.querySelector('.js-create-product-fields').hidden = !showNewFields
  for (const el of document.querySelectorAll('.js-create-product-fields .js-optionally-required')) {
    el.required = showNewFields
  }
  const fragment = document.querySelector('.js-referrers-fragment')
  if (!showNewFields) {
    fragment.src = fragment.getAttribute('data-src').replace(/_id_/, event.currentTarget.value)
  } else {
    fragment.innerHTML = ''
  }
})

on('change', '.js-image-preview-field', event => {
  const label = event.currentTarget.closest('.js-image-preview')
  if (event.currentTarget.files.length > 0) {
    const reader = new FileReader()
    reader.onload = evt => {
      label.setAttribute('style', `background-image: url(${evt.target.result})`)
    }
    reader.readAsDataURL(event.currentTarget.files[0])
  } else {
    label.removeAttribute('style')
  }
})

observe('.js-notifications', el => {
  let loaded = false
  el.addEventListener('toggle', () => {
    if (!el.open || loaded) return
    loaded = true
    el.classList.remove('is-unread')
    const form = document.querySelector('.js-notifications-form')
    if ('requestSubmit' in form) {
      form.requestSubmit()
    } else {
      form.dispatchEvent(new Event('submit', {bubbles: true}))
    }
  })
})

on('ajax:success', '.js-notifications-form', event => {
  const [_data, _status, xhr] = event.detail;
  const div = document.createElement('div')
  div.innerHTML = xhr.responseText
  event.currentTarget.replaceWith(div);
})

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0
}

const observer = new IntersectionObserver((entries, observer) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      loadNextPage(entry.target)
    }
  }
}, options)

observe('.js-next-page', el => {
  observer.observe(el)
})

async function loadNextPage(el) {
  const container = document.querySelector('.js-lazy-load-container')
  if (!container) return

  
  el.closest('nav').remove()
  const loader = document.createElement('div')
  loader.classList.add('loader', 'mb5', 'mt6')
  container.append(loader)

  const body = await (await fetch(el.href, {headers: {'X-lazy-load': 1}})).text()
  const div = document.createElement('div')
  div.innerHTML = body
  const newContainer = div.querySelector('.js-lazy-load-container')
  const newNav = newContainer.querySelector('nav')

  // last page
  if (!newNav.querySelector('.js-next-page')) {
    newNav.remove()
  }
  for (const child of [...newContainer.children]) {
    container.append(child)
  }

  loader.remove()
}

observe('details.details-popover', function(details) {
  details.addEventListener('toggle', function(event) {
    if (event.currentTarget.open) {
      const popover = event.currentTarget.querySelector(':scope > .popover-content')
      if (!popover) return
      popover.setAttribute('tabindex', '-1')
      popover.focus()
    }
  })
})
