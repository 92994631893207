import consumer from 'channels/consumer'

class ActionCableElement extends HTMLElement {
  subsciption = null

  connectedCallback() {
    const self = this
    this.subsciption = consumer.subscriptions.create({channel: this.channel, type: this.type, id: this.id}, {
      received() {
        self.received()
      }
    })
  }

  disconnectedCallback() {
    this.subsciption.unsubscribe()
  }

  get channel() {
    return this.getAttribute('channel')
  }

  get type() {
    return this.getAttribute('type')
  }

  get id() {
    return this.getAttribute('id')
  }

  async received() {
    const partial = this.getAttribute('partial')
    const baseURL = getMetaByName('ac-partial-path')
    const body = {
      partial: partial,
      type: this.type,
      id: this.id
    }
    const res = await fetch(baseURL, {
      method: 'post', 
      headers: {'X-CSRF-Token': getMetaByName('csrf-token'), 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    if (res.status !== 200) {
      console.warn('<action-cable> error', res)
      return
    }
    const text = await res.text()
    this.outerHTML = text
  }
}

function getMetaByName(name) {
  return document.head.querySelector<HTMLMetaElement>(`[name='${name}']`).content
}

if (!window.customElements.get('action-cable')) {
  window.customElements.define('action-cable', ActionCableElement)
}
