import { on } from 'delegated-events'
import {observe} from 'selector-observer'

const keys = {
  '@': 'data-mentions-url',
  '#': 'data-products-url'
}

observe('text-expander', function(el) {
  el.addEventListener('text-expander-change', async function(event: CustomEvent) {
    const {key, provide, text} = event.detail
    if (!Object.keys(keys).includes(key)) return

    provide(getResults(key, el, text))
  }, true)

  el.addEventListener('text-expander-value', async function(event: CustomEvent) {
    const {key, item, _} = event.detail
    if (!Object.keys(keys).includes(key)) return
    event.detail.value = item.getAttribute('data-value')
  }, true)
})

const cached: {[key: string]: string} = {}
observe('[data-preview-selector][data-preview-url] textarea', {
  constructor: HTMLTextAreaElement, 
  initialize(el) {
    el.addEventListener('focus', function() {preview(el) })
    let delaying = null
    el.addEventListener('input', function() {
      if (delaying) {
        clearTimeout(delaying)
      }

      delaying = setTimeout(() => {
        delaying = null
        preview(el)
      }, 800)
    })
  }
})

async function preview(el: HTMLTextAreaElement) {
  if (!el.value) return
  const parent = el.closest('text-expander')
  const dest = document.querySelector<HTMLElement>(parent.getAttribute('data-preview-selector'))
  const url = parent.getAttribute('data-preview-url')
  const html = cached[el.value] || await (await fetch(url, {method: 'post', body: new URLSearchParams({content: el.value})})).text()
  dest.hidden = !html
  cached[el.value] = html
  dest.innerHTML = html
}

async function getResults(key: string, el: Element, text: string) {
  const menu = el.querySelector<HTMLElement>(`[data-suggestions-for='${key}']`) || await makeMenu(el.getAttribute(keys[key]))
  const matched = search(menu, text)
  return {matched, fragment: menu}
}

async function makeMenu(url: string): Promise<HTMLElement> {
  const suggestionsHTML = await (await fetch(url)).text()
  const div = document.createElement('div')
  div.innerHTML = suggestionsHTML
  return div.firstElementChild as HTMLElement
}

function search(menu: HTMLElement, text: string) {
  let matched = false
  let count = 0
  for (const el of Array.from(menu.children)) {
    const thisMatched = el.textContent.toLowerCase().includes(text.toLowerCase())
    if (thisMatched) {
      matched = true
      count++
    }
    ;(el as HTMLElement).hidden = !thisMatched || count > 5
  }
  return matched
}
